/* .slider__div444, .slider__div404 {
    display: none;
}
.slider__div777, .slider__div7777 {
    display: none;
} */
.slider__div1 {
  overflow: hidden;
  position: relative;
  top: -30vw;
  margin-left: 2vw;
}
.slider__div2 {
  margin-left: 5vw;
  /* overflow: hidden; */
  /* margin-bottom: 10vw; */
  /* overflow: hidden; */
}
.slider__div3 {
  display: flex;
  position: absolute;
  overflow: hidden;
  height: 27vw;
  width: 340px;
  z-index: 999;
}
#slider__div4 {
  height: 27vw;
  width: 340px;
}
#slider__div444 {
  height: 27vw;
  width: 340px;
}
#slider__div404 {
  height: 27vw;
  width: 340px;
  position: relative;
  /* left: -5vw; */
}
.slider__divExt0 {
  display: flex;
  height: 27vw;
  /* width: 340px; */
  /* justify-content: space-between; */
}

.slider__img1 {
  height: 27vw;
  width: 340px;
}
/* .slider__img1:nth-child(2) {
    height: 27vw;
    width: 400px;
} */
.slider__div6 {
  width: 600px;
  height: 600px;
  display: flex;
  position: relative;
  /* left: 20vw; */
}
#slider__div7 {
  margin-left: 32vw;
  margin-top: 0.4vw;
  opacity: 0;
}
#slider__div777 {
  margin-left: -52vw;
  margin-top: 0.4vw;
  opacity: 0;
}
#slider__div7777 {
  margin-left: -52vw;
  margin-top: 0.4vw;
  opacity: 0;
}
.slider__div8 {
  width: 52vw;
  font-size: 2.1vw;
}
.slider__div9 {
  display: flex;
  flex-flow: column;
  gap: 0.5vw;
  margin-top: 2.5vw;
}
.slider__div9 > p {
  font-size: 1.3vw;
  font-weight: 600;
  color: #bd8600;
}
.slider__div9 a{
  vertical-align: middle;
}
.slider__div10 {
  border: 0.01vw solid #fff;
  rotate: 90deg;
  margin-left: 1.1vw;
}
.slider__div11 {
  position: relative;
    bottom: 0;
    right: 0vw;
  overflow: hidden;
  display: flex;
  left: 33vw;
  margin-top: 22vw;
}
.arrowF {
  font-size: 3.7vw !important;
}
.slider__div11 div:nth-child(1) {
  margin-left: 0vw;
}
.slider__div11 div {
  margin-left: 2vw;
}
.slider__div12 svg{
  border-radius: 50%;
    border: 1px solid #fff;
    font-size: 3.5vw;
    padding: 0.5vw;
}

@media screen and  (max-width: 1150px){
  .slider__div3 {
    height: 27vw;
    width: 320px;
}
#slider__div4 {
  height: 27vw;
  width: 320px;
}
.slider__img1 {
height: 27vw;
width: 320px;
}
.slider_a_img{
  width: 340px;
}
.slider__div11 {
  position: relative;
    bottom: 0;
    right: 0vw;
  overflow: hidden;
  display: flex;
  left: 18vw;
  margin-top: 21vw;
}
.arrowF {
  font-size: 3.8vw !important;
}
}

@media screen and  (max-width: 1024px){
  .slider__div3 {
    height: 27vw;
    width: 260px;
}
#slider__div4 {
  height: 27vw;
  width: 260px;
}
.slider__img1 {
height: 27vw;
width: 260px;
}
#slider__div444 {
  margin-left: 8.3vw;
}
#slider__div404 {
  margin-left: 8.3vw;
  /* left: -5vw; */
}
.slider__div11 {
  position: relative;
    bottom: 0;
    right: 0vw;
  overflow: hidden;
  display: flex;
  left: 10vw;
  margin-top: 22vw;
}
.arrowF {
  font-size: 4vw !important;
}
}
@media screen and  (max-width: 900px){
  .slider__div3 {
    height: 25vw;
    width: 230px;
}
#slider__div4 {
  height: 25vw;
  width: 230px;
}
.slider__img1 {
height: 25vw;
width: 230px;
}
#slider__div444 {
  margin-left: 12.2vw;
}
#slider__div404 {
  margin-left: 12.2vw;
}
.slider__div11 {
  position: relative;
    bottom: 0;
    right: 0vw;
  overflow: hidden;
  display: flex;
  left: 3vw;
  margin-top: 21vw;
}
.arrowF {
  font-size: 4.2vw !important;
}
}
@media screen and  (max-width: 768px){
  .slider__div11 {
    position: relative;
      bottom: 0;
      right: 0vw;
    overflow: hidden;
    display: flex;
    left: -7vw;
    margin-top: 21vw;
  }
  .arrowF {
    font-size: 4.2vw !important;
}
 }

@media screen and  (max-width: 750px){
  .slider__div3 {
    height: 25vw;
    width: 200px;
}
#slider__div4 {
  height: 25vw;
  width: 200px;
}
.slider__img1 {
height: 25vw;
width: 200px;
}
#slider__div444 {
  margin-left: 25.2vw;
}
#slider__div404 {
  margin-left: 25.2vw;
}
.slider__div11 {
  position: relative;
    bottom: 0;
    right: 0vw;
  overflow: hidden;
  display: flex;
  left: -12vw;
  margin-top: 21vw;
}
.arrowF {
  font-size: 4.2vw !important;
}
}


@media (max-width: 550px) { 
  .slider__div1 {
    overflow: hidden;
    position: relative;
    top: -30vw;
    margin-left: 2vw;
  }
  .slider__div2 {
    margin-left: 5vw;
  }
  .slider__div3 {
    display: flex;
    position: absolute;
    overflow: hidden;
    height: 37vw;
    width: 32.1vw;
    /* z-index: -9999; */
  }
  #slider__div4 {
    height: 37vw;
    width: 32.1vw;
  }
  #slider__div444 {
    height: 27vw;
    width: 22.1vw;
    margin-left: 25.2vw;
  }
  #slider__div404 {
    height: 27vw;
    width: 22.1vw;
    position: relative;
    margin-left: 25.2vw;
    /* left: -5vw; */
  }
  .slider__divExt0 {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 37vw;
    /* width: 22.1vw; */
    /* justify-content: space-between; */
  }
  
  .slider__img1 {
    height: 37vw;
    width: 32.1vw;
  }
  /* .slider__img1:nth-child(2) {
      height: 27vw;
      width: 400px;
  } */
  .slider__div5 {
    position: relative;
    margin-top: 5vw;
    /* z-index: -99999; */
  }
  .slider__div6 {
    width: 600px;
    height: 600px;
    display: flex;
    position: relative;
    /* left: 20vw; */
  }
  #slider__div7 {
    margin-left: 32vw;
    margin-top: 0.4vw;
    opacity: 0;
  }
  #slider__div777 {
    margin-left: -63vw;
    margin-top: 0.4vw;
    opacity: 0;
  }
  #slider__div7777 {
    margin-left: -52vw;
    margin-top: 0.4vw;
    opacity: 0;
  }
  .slider__div8 {
    width: 57vw;
    margin-left: 2vw;
    font-size: 2.9vw;
    margin-top: -5vw;
  }
  .slider__div9 {
    display: flex;
    margin-top: 2.5vw;
  }
  .slider__div9 > p {
    font-size: 3.2vw;
    font-weight: 600;
    margin-left: 2vw;
  }
  .slider__div10 {
    border: 0.01vw solid #fff;
    rotate: 90deg;
    margin-left: 1.1vw;
  }
  /* .slider__div11 {
    position: relative;
    margin-top: 20vw;
    margin-left: -33vw;
    overflow: auto;
    display: flex;
    left: -30vw;
  }
  .slider__div11 div:nth-child(1) {
    margin-left: 0vw;
  }
  .slider__div11 div {
    margin-left: 2vw;
  } */
  .slider__svg00003 {
    height: 5vw;
  }
  .slider__div12 {
    position: relative;
    left: -10vw;
  }
  .slider__div11 {
    position: relative;
      bottom: 0;
      right: 0vw;
    overflow: visible;
    display: flex;
    left: -40vw !important;
    /* margin-left: -50vw !important; */
    margin-top: 30vw;
    z-index: 9999999;
    
  }
  .arrowF {
    font-size: 5.2vw !important;
  }
  }


@media (max-width: 426px) {
  #slider__div4 {
    height: 37vw;
    width: 32.1vw;
    margin-left: 0.2vw;
  }
  #slider__div444 {
    height: 27vw;
    width: 22.1vw;
    margin-left: 50.2vw;
  }
  #slider__div404 {
    height: 27vw;
    width: 22.1vw;
    position: relative;
    /* margin-left: 65.2vw; */
    position: relative;
    left: 40vw;
    /* left: -5vw; */
  }
  .slider__div11 {
    position: relative;
      bottom: 0;
      right: 0vw;
      overflow: visible;
    /* overflow: auto; */
    display: flex;
    /* left: -52vw !important; */
    margin-left: -27vw !important;
    margin-top: 30vw;
    z-index: 9999999;
    
  }
  .arrowF {
    font-size: 5vw !important;
    z-index: 999999;
   
  }
}