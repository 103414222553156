.second__div2 {
    position: relative;
    top: -30vw;
    margin-left: 15vw;
    /* left: 15vw; */
}

.second__img1 {
    height: 30vw;
}

.second__div3 {
    display: flex;
}
.second__div5 {
    margin-left: 5vw;
    margin-top: 5vw;
}
.second__div6 {
    width: 40vw;
    font-size: 1.3vw;
    line-height: 2.2vw;
}

.second__div7 {
    margin-top: 3vw;
    font-size: 1.2vw;
    color: #bd8600;
}
.second__div7 p:nth-child(1) {
    font-size: 1.5vw;
}
.second__div7 p:nth-child(2) {
    margin-top: 0.5vw;
    font-size: 1vw;
}

.mySwiper {
    position: relative;
    left: -7vw;
    --swiper-navigation-color: #bd8600;
    --swiper-navigation-fontSize: "2vw";
    --swiper-navigation-size: "3vw"
}


.swiper {
    width: 100%;
    height: 100%;
}
  
.swiper-slide {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
}

.swiper-button-next, .swiper-button-prev {
    height: 3vw !important;
    font-size: 3vw !important;
}

@media screen and (max-width: 430px){
    .second__div2 {
        position: relative;
        top: -30vw;
        margin-left: 15vw;
        /* left: 15vw; */
    }
    
    .second__img1 {
        height: 80vw;
    }
    
    .second__div3 {
        display: block;
    }
    .second__div5 {
        margin-left: 0vw;
        margin-top: 5vw;
    }
    .second__div6 {
        width: 60vw;
        font-size: 3vw;
        line-height: 4vw;
    }
    
    .second__div7 {
        margin-top: 3vw;
        font-size: 1.2vw;
        color: #bd8600;
    }
    .second__div7 p:nth-child(1) {
        font-size: 3vw;
    }
    .second__div7 p:nth-child(2) {
        margin-top: 0.8vw;
        font-size: 2vw;
    }
    
    .mySwiper {
        position: relative;
        left: -7vw;
        --swiper-navigation-color: #bd8600;
        --swiper-navigation-fontSize: "2vw";
        --swiper-navigation-size: "4vw"
    }
    
    
    .swiper {
        width: 100%;
        height: 100%;
    }
      
    .swiper-slide {
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
    }
}